import { Container } from "../../components/home/container"
import { Footer }  from "../../components/home/footer"

export const Home = ( ) => {

  return (
    <>
      <section>
        <Container />
        <Footer />
      </section>
    </>

  )
}
